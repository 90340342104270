import * as React from 'react'
const SvgPhone = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={40} height={40} fill='none' {...props}>
    <circle cx={20} cy={20} r={20} fill='#FF883E' fillOpacity={0.1} />
    <path
      fill='#FF883E'
      d='M29 24.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07a1 1 0 0 1 .998-.93h3.536a.5.5 0 0 1 .498.45c.023.23.044.413.064.552a13.9 13.9 0 0 0 1.208 4.001c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.9 13.9 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498H29Z'
    />
  </svg>
)
export default SvgPhone
