import * as React from 'react'
const SvgLogoMulti = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={40} height={32} fill='none' {...props}>
    <path fill='#2B2B2B' d='M23.283 3.392a3.31 3.31 0 1 0-6.62-.082 3.31 3.31 0 0 0 6.62.082Z' />
    <path
      fill='#FF883E'
      d='M32.974 11.862A2.127 2.127 0 0 0 34.8 8.64c-.048.016-.096.04-.135.055a4.734 4.734 0 0 1-1.691.326 4.775 4.775 0 0 1-1.699-.326c-.04-.016-.087-.032-.127-.047a2.127 2.127 0 0 0 1.826 3.215Z'
    />
    <path
      fill='#FF883E'
      d='M39.26 5.313c-1.714 9.581-6.794 8.35-8.953 8.359-1.508 3.373-.151 11.494 5.485 14.899-7.875-11.002 4.15-12.034 3.468-23.258ZM31.45 8.227c.008 0 .008 0 .016.007 1.016.381 2 .381 3.008 0h.016l.294-1.793a6.553 6.553 0 0 0-3.628 0l.294 1.786c0-.008 0-.008 0 0Zm.826-1.128h.484v-.484h.412V7.1h.485v.413h-.485v.493h-.412V7.52h-.484V7.1Z'
    />
    <path
      fill='#169B62'
      d='M7.018 11.862a2.127 2.127 0 0 0 2.127-2.127c0-.278-.055-.54-.15-.778H5.04a2.12 2.12 0 0 0-.15.778c0 1.175.952 2.127 2.127 2.127Z'
    />
    <path
      fill='#169B62'
      d='M9.693 13.672c-2.16-.016-7.24 1.222-8.954-8.359C.056 16.537 12.074 17.57 4.208 28.58c5.636-3.413 6.993-11.534 5.485-14.907ZM6.748 6.925h.516c.143 0 .262-.12.262-.262v-.778a.264.264 0 0 0-.262-.262h-.516a.264.264 0 0 0-.262.262v.778c0 .15.119.262.262.262Z'
    />
    <path
      fill='#169B62'
      d='M5.12 8.473a.22.22 0 0 0 .072.008H8.74l-.008-.008h.16a.255.255 0 0 0 .19-.246v-.262a.264.264 0 0 0-.262-.262c0-.675-.373-1.262-.921-1.58v.54c0 .35-.286.635-.635.635h-.516a.637.637 0 0 1-.635-.635v-.54c-.548.31-.92.905-.92 1.58a.264.264 0 0 0-.263.262v.262c0 .119.08.214.19.246Z'
    />
    <path
      fill='#2B2B2B'
      d='M30.505.139c-1.127 4.707-5.183 8.691-8.731 9.779l-1.207 1.476.754 3.652-1.357 2.532-1.357-2.532.754-3.652-1.215-1.484C14.63 8.815 10.55 4.838 9.423.139c0 8.723 13.137 13.914.452 31.861 8.065-5.881 9.97-12.954 10.081-13.176.111.222 2.016 7.302 10.08 13.176-12.668-17.955.47-23.138.47-31.861Z'
    />
  </svg>
)
export default SvgLogoMulti
